import { Icon } from '@iconify/react';
import alertIcon from '@iconify/icons-eva/alert-triangle-fill';
// material
import { alpha, styled } from '@mui/material/styles';
import { Card, Skeleton, Typography } from '@mui/material';
import { client } from 'src/utils/graphql/GraphqlClient';
import { getToken } from 'src/utils/tokenUtils';
import { getUrgentVehiclesCount } from 'src/utils/graphql/queries/vehicles/VehicleQueries';
import { useEffect, useState } from 'react';
// utils

// ----------------------------------------------------------------------

const RootStyle = styled(Card)(({ theme }) => ({
  boxShadow: 'none',
  textAlign: 'center',
  padding: theme.spacing(5, 0),
  color: theme.palette.error.darker,
  backgroundColor: theme.palette.error.lighter
}));

const IconWrapperStyle = styled('div')(({ theme }) => ({
  margin: 'auto',
  display: 'flex',
  borderRadius: '50%',
  alignItems: 'center',
  width: theme.spacing(8),
  height: theme.spacing(8),
  justifyContent: 'center',
  marginBottom: theme.spacing(3),
  color: theme.palette.error.dark,
  backgroundImage: `linear-gradient(135deg, ${alpha(theme.palette.error.dark, 0)} 0%, ${alpha(
    theme.palette.error.dark,
    0.24
  )} 100%)`
}));

// ----------------------------------------------------------------------

export default function UrgentVehicles() {
  const [urgentVehiclesCount, setUrgentVehiclesCount] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('Unexpected Error. Please check your network');

  const getNumberOfUrgentVehicles = async () => {
    const variables = {};
    const header = {
      Authorization: `Bearer ${getToken('access-token')}`
    };

    try {
      const urgentVehiclesResponse = await client.request(
        getUrgentVehiclesCount,
        variables,
        header
      );
      const urgentVehicles = urgentVehiclesResponse.getUrgentVehiclesCount;
      setUrgentVehiclesCount(urgentVehicles);
      setIsLoading(false);
    } catch (error) {
      if (error.response && error.response.status === 500) {
        setErrorMessage(error.response.errors[0].message);
      }
      setIsLoading(false);
      setIsError(true);
      console.log(error);
    }
  };
  useEffect(() => {
    getNumberOfUrgentVehicles();
  }, []);
  return (
    <>
      {!isLoading && urgentVehiclesCount && urgentVehiclesCount ? (
        <RootStyle>
          <IconWrapperStyle>
            <Icon icon={alertIcon} width={24} height={24} />
          </IconWrapperStyle>
          <Typography variant="h3">{urgentVehiclesCount}</Typography>
          <Typography variant="subtitle2" sx={{ opacity: 0.72 }}>
            Urgent Vehicles
          </Typography>
        </RootStyle>
      ) : (
        <Skeleton variant="rectangular" width="100%" height="100%" style={{ borderRadius: '16px' }}>
          <div style={{ paddingTop: '57%' }} />
        </Skeleton>
      )}
    </>
  );
}
