import { gql } from 'graphql-request';

export const refreshAccessAndRefreshToken = gql`
  query refreshTokens {
    refreshTokens {
      refreshToken
      accessToken
    }
  }
`;

export const getUsers = gql`
  query getExpressUsers($pageNumber: Int!, $rowsPerPage: Int!, $searchTerm: String) {
    getExpressUsers(pageNumber: $pageNumber, rowsPerPage: $rowsPerPage, searchTerm: $searchTerm) {
      users {
        _id
        name
        phoneNumber
        email
        date
        numberOfRides
      }
      totalRows
      totalPages
    }
  }
`;

export const getUsersCount = gql`
  query getExpressUsersCount {
    getExpressUsersCount
  }
`;
