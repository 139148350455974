// material
import { Box, Grid, Container, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import EmissionsSavedCard from 'src/components/_dashboard/app/EmissionsSavedCard';
import { client } from 'src/utils/graphql/GraphqlClient';
import { refreshAccessAndRefreshToken } from 'src/utils/graphql/queries/users/UsersQueries';
import { checkAccessAndRefreshTokensValidity, getToken } from 'src/utils/tokenUtils';
// components
import Page from '../components/Page';
import {
  AppNewUsers,
  AppItemOrders,
  AppCurrentVisits,
  VehiclesCoordinates,
  AppConversionRates,
  UrgentVehicles
} from '../components/_dashboard/app';

// ----------------------------------------------------------------------

export default function DashboardApp() {
  const [isTokenCheckInProgress, setIsTokenCheckInProgress] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();

  const refreshTokens = async () => {
    const header = {
      authorization: `Bearer ${getToken('refresh-token')}`
    };
    const varaibles = {};

    try {
      const refreshTokenResponse = await client.request(
        refreshAccessAndRefreshToken,
        varaibles,
        header
      );
      const { refreshToken, accessToken } = refreshTokenResponse.refreshTokens;
      localStorage.setItem('access-token', accessToken);
      localStorage.setItem('refresh-token', refreshToken);
      return setIsTokenCheckInProgress(false);
    } catch (error) {
      return navigate('/login', { replace: true });
    }
  };
  useEffect(() => {
    try {
      const tokenValidity = checkAccessAndRefreshTokensValidity();
      if (tokenValidity === 'refresh-access') {
        return refreshTokens();
      }
      if (tokenValidity === 'proceed') {
        return setIsTokenCheckInProgress(false);
      }
      return navigate('/login', { replace: true });
    } catch (error) {
      console.log(error);
      return navigate('/login', { replace: true });
    }
  }, []);
  useEffect(() => {
    if (!isTokenCheckInProgress) {
      console.log('fetching');
      setIsLoading(false);
    }
  }, [isTokenCheckInProgress]);
  return (
    <Page title="Dashboard | Rabbit Express">
      <Container maxWidth="xl">
        {isLoading ? (
          <div>loading</div>
        ) : (
          <>
            <Box sx={{ pb: 5 }}>
              <Typography variant="h4">Hi, Welcome back</Typography>
            </Box>
            <Grid container spacing={3}>
              <Grid item xs={6} sm={6} md={3}>
                <EmissionsSavedCard />
              </Grid>
              <Grid item xs={6} sm={6} md={3}>
                <AppNewUsers />
              </Grid>
              <Grid item xs={6} sm={6} md={3}>
                <AppItemOrders />
              </Grid>
              <Grid item xs={6} sm={6} md={3}>
                <UrgentVehicles />
              </Grid>

              <Grid item xs={12} md={12} lg={12}>
                <VehiclesCoordinates />
              </Grid>

              <Grid item xs={12} md={6} lg={8}>
                {/* <AppConversionRates /> */}
              </Grid>

              <Grid item xs={12} md={6} lg={4}>
                {/* <AppCurrentVisits /> */}
              </Grid>
            </Grid>
          </>
        )}
      </Container>
    </Page>
  );
}
