import PersonIcon from '@mui/icons-material/Person';
// material
import { alpha, styled } from '@mui/material/styles';
import { Card, Skeleton, Typography } from '@mui/material';
// utils
import { useEffect, useState } from 'react';
import { client } from 'src/utils/graphql/GraphqlClient';
import { getToken } from 'src/utils/tokenUtils';
import { getUsersCount } from 'src/utils/graphql/queries/users/UsersQueries';

// ----------------------------------------------------------------------

const RootStyle = styled(Card)(({ theme }) => ({
  boxShadow: 'none',
  textAlign: 'center',
  padding: theme.spacing(5, 0),
  color: theme.palette.info.darker,
  backgroundColor: theme.palette.info.lighter
}));

const IconWrapperStyle = styled('div')(({ theme }) => ({
  margin: 'auto',
  display: 'flex',
  borderRadius: '50%',
  alignItems: 'center',
  width: theme.spacing(8),
  height: theme.spacing(8),
  justifyContent: 'center',
  marginBottom: theme.spacing(3),
  color: theme.palette.info.dark,
  backgroundImage: `linear-gradient(135deg, ${alpha(theme.palette.info.dark, 0)} 0%, ${alpha(
    theme.palette.info.dark,
    0.24
  )} 100%)`
}));

// ----------------------------------------------------------------------

export default function AppNewUsers() {
  const [expressUsersCount, setExpressUsersCount] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('Unexpected Error. Please check your network');

  const getNumberOfExpressUsers = async () => {
    const variables = {};
    const header = {
      Authorization: `Bearer ${getToken('access-token')}`
    };

    try {
      const expressUsersCountResponse = await client.request(getUsersCount, variables, header);
      const count = expressUsersCountResponse.getExpressUsersCount;
      setExpressUsersCount(count);
      setIsLoading(false);
    } catch (error) {
      if (error.response && error.response.status === 500) {
        setErrorMessage(error.response.errors[0].message);
      }
      setIsLoading(false);
      setIsError(true);
      console.log(error);
    }
  };
  useEffect(() => {
    getNumberOfExpressUsers();
  }, []);

  return (
    <>
      {!isLoading && expressUsersCount ? (
        <RootStyle>
          <IconWrapperStyle>
            <PersonIcon />
          </IconWrapperStyle>
          <Typography variant="h3">{expressUsersCount}</Typography>
          <Typography variant="subtitle2" sx={{ opacity: 0.72 }}>
            Express Drivers
          </Typography>
        </RootStyle>
      ) : (
        <Skeleton variant="rectangular" width="100%" height="100%" style={{ borderRadius: '16px' }}>
          <div style={{ paddingTop: '57%' }} />
        </Skeleton>
      )}
    </>
  );
}
