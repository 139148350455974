import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
// material
import { CircularProgress } from '@mui/material';
import Backdrop from '@mui/material/Backdrop';
import { checkAccessAndRefreshTokensValidity, getToken } from 'src/utils/tokenUtils';
import { client } from 'src/utils/graphql/GraphqlClient';
import { refreshAccessAndRefreshToken } from 'src/utils/graphql/queries/users/UsersQueries';
// components
import VehicleDetailsCard from 'src/components/_dashboard/vehicles/details/VehicleDetailsCard';
import Page from '../components/Page';

// ----------------------------------------------------------------------

export default function VehicleDetails() {
  const [isTokenCheckInProgress, setIsTokenCheckInProgress] = useState(true);
  const navigate = useNavigate();

  const refreshTokens = async () => {
    const header = {
      authorization: `Bearer ${getToken('refresh-token')}`
    };
    const varaibles = {};

    try {
      const refreshTokenResponse = await client.request(
        refreshAccessAndRefreshToken,
        varaibles,
        header
      );
      const { refreshToken, accessToken } = refreshTokenResponse.refreshTokens;
      localStorage.setItem('access-token', accessToken);
      localStorage.setItem('refresh-token', refreshToken);
      return setIsTokenCheckInProgress(false);
    } catch (error) {
      return navigate('/login', { replace: true });
    }
  };
  useEffect(() => {
    try {
      const tokenValidity = checkAccessAndRefreshTokensValidity();
      if (tokenValidity === 'refresh-access') {
        return refreshTokens();
      }
      if (tokenValidity === 'proceed') {
        return setIsTokenCheckInProgress(false);
      }
      return navigate('/login', { replace: true });
    } catch (error) {
      console.log(error);
      return navigate('/login', { replace: true });
    }
  }, []);

  useEffect(() => {
    if (!isTokenCheckInProgress) {
      setIsTokenCheckInProgress(false);
    }
  }, [isTokenCheckInProgress]);

  return (
    <Page title="Vehicles | Rabbit Express">
      {isTokenCheckInProgress ? (
        <Backdrop
          sx={{ color: 'white', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open
        >
          <CircularProgress color="primary" />
        </Backdrop>
      ) : (
        <VehicleDetailsCard />
      )}
    </Page>
  );
}
