import * as React from 'react';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { formatDistanceToNow } from 'date-fns';
import { Button } from '@mui/material';
import Label from 'src/components/Label';
import VehicleLocationMap from 'src/components/maps/VehicleLocationMap';

const CollapsableRow = ({ rowData, handleUnlockClick }) => {
  const [open, setOpen] = React.useState(false);
  const {
    _id,
    code,
    iotStatus,
    status,
    lastModification,
    lockStatus,
    realBatteryValue,
    lat,
    long
  } = rowData;
  let lockColor = 'success';
  if (lockStatus === 'unlocked' && iotStatus !== 'on') {
    lockColor = 'error';
  }
  const coordinates = {
    lat,
    lng: long
  };
  return (
    <>
      <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
        <TableCell>
          <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell component="th" scope="row">
          {code}
        </TableCell>
        <TableCell align="left">{realBatteryValue?.toFixed(2)} %</TableCell>
        <TableCell align="left">{formatDistanceToNow(new Date(lastModification))} ago</TableCell>
        <TableCell align="left">
          <Label variant="ghost" color={lockColor}>
            {lockStatus?.toUpperCase()}
          </Label>
        </TableCell>
        <TableCell align="left">
          <Label variant="ghost" color={(status === 'off' && 'error') || 'success'}>
            {status}
          </Label>
        </TableCell>
        <TableCell align="left">
          <Label variant="ghost" color={(iotStatus === 'off' && 'error') || 'success'}>
            {iotStatus}
          </Label>
        </TableCell>
        <TableCell align="left">
          <Button variant="contained" onClick={() => handleUnlockClick(code)}>
            UNLOCK
          </Button>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <Typography variant="h6" gutterBottom component="div">
                Current Location
              </Typography>
              <VehicleLocationMap coordinates={coordinates} />
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
};

export default CollapsableRow;
