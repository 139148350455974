import { gql } from 'graphql-request';

export const setUrgentVehicleCollected = gql`
  mutation setUrgentVehicleCollected($code: String!) {
    setUrgentVehicleCollected(code: $code) {
      _id
      code
      long
      lat
      isInStorage
      area {
        name
      }
      lastModification
      status
      rating
      phoneBattery
      battery
      realBatteryValue
      batteryTravelRange
      iotStatus
      lockStatus
      isUrgent
    }
  }
`;

export const unlockVehicle = gql`
  mutation unlockVehicle($code: String!) {
    unlockVehicle(code: $code)
  }
`;
