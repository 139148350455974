import TwoWheelerIcon from '@mui/icons-material/TwoWheeler';
// material
import { alpha, styled } from '@mui/material/styles';
import { Card, Skeleton, Typography } from '@mui/material';
// utils
import { useEffect, useState } from 'react';
import { client } from 'src/utils/graphql/GraphqlClient';
import { todayRidesCount } from 'src/utils/graphql/queries/rides/ridesQueries';
import { getToken } from 'src/utils/tokenUtils';

// ----------------------------------------------------------------------

const RootStyle = styled(Card)(({ theme }) => ({
  boxShadow: 'none',
  textAlign: 'center',
  padding: theme.spacing(5, 0),
  color: theme.palette.warning.darker,
  backgroundColor: theme.palette.warning.lighter
}));

const IconWrapperStyle = styled('div')(({ theme }) => ({
  margin: 'auto',
  display: 'flex',
  borderRadius: '50%',
  alignItems: 'center',
  width: theme.spacing(8),
  height: theme.spacing(8),
  justifyContent: 'center',
  marginBottom: theme.spacing(3),
  color: theme.palette.warning.dark,
  backgroundImage: `linear-gradient(135deg, ${alpha(theme.palette.warning.dark, 0)} 0%, ${alpha(
    theme.palette.warning.dark,
    0.24
  )} 100%)`
}));

// ----------------------------------------------------------------------

export default function AppItemOrders() {
  const [ridesCount, setRidesCount] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('Unexpected Error. Please check your network');

  const getNumberOfTodayRides = async () => {
    const variables = {};
    const header = {
      Authorization: `Bearer ${getToken('access-token')}`
    };

    try {
      const todaySumOfRidesResponse = await client.request(todayRidesCount, variables, header);
      const count = todaySumOfRidesResponse.sumOfTodayRides;
      setRidesCount(count);
      setIsLoading(false);
    } catch (error) {
      if (error.response && error.response.status === 500) {
        setErrorMessage(error.response.errors[0].message);
      }
      setIsLoading(false);
      setIsError(true);
      console.log(error);
    }
  };
  useEffect(() => {
    getNumberOfTodayRides();
  }, []);
  return (
    <>
      {!isLoading && ridesCount !== null ? (
        <RootStyle>
          <IconWrapperStyle>
            <TwoWheelerIcon />
          </IconWrapperStyle>
          <Typography variant="h3">{ridesCount} Rides</Typography>
          <Typography variant="subtitle2" sx={{ opacity: 0.72 }}>
            Since Today
          </Typography>
        </RootStyle>
      ) : (
        <Skeleton variant="rectangular" width="100%" height="100%" style={{ borderRadius: '16px' }}>
          <div style={{ paddingTop: '57%' }} />
        </Skeleton>
      )}
    </>
  );
}
