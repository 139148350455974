import { Icon } from '@iconify/react';
import pieChart2Fill from '@iconify/icons-eva/pie-chart-2-fill';
import peopleFill from '@iconify/icons-eva/people-fill';
import shoppingBagFill from '@iconify/icons-eva/shopping-bag-fill';
import fileTextFill from '@iconify/icons-eva/file-text-fill';
import carFill from '@iconify/icons-eva/car-fill';

// ----------------------------------------------------------------------

const getIcon = (name) => <Icon icon={name} width={22} height={22} />;

const sidebarConfig = [
  {
    title: 'dashboard',
    path: '/dashboard/app',
    icon: getIcon(pieChart2Fill)
  },
  {
    title: 'Rides',
    path: '/dashboard/rides',
    icon: getIcon(peopleFill)
  },
  {
    title: 'Vehicles',
    path: '/dashboard/vehicles',
    icon: getIcon(carFill)
  },
  {
    title: 'Team',
    path: '/dashboard/users',
    icon: getIcon(peopleFill)
  },
];

export default sidebarConfig;
