import React, { useState, useEffect } from 'react';
import { sentenceCase } from 'change-case';
import {
  Card,
  Table,
  Stack,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
  Skeleton,
  Snackbar,
  Alert,
  Button
} from '@mui/material';
import Scrollbar from 'src/components/Scrollbar';
import { getToken } from 'src/utils/tokenUtils';
import { client } from 'src/utils/graphql/GraphqlClient';
import { getRidesWithPage } from 'src/utils/graphql/queries/rides/ridesQueries';
import Label from 'src/components/Label';
import { differenceInMinutes, format } from 'date-fns';
import SearchNotFound from 'src/components/SearchNotFound';
import ImageModal from 'src/components/ImageModal';
import { RideListHead, RideListToolbar, RideMoreMenu } from '.';
import USERLIST from '../../../_mocks_/user';

const TABLE_HEAD = [
  { id: 'name', label: 'Name', alignRight: false },
  { id: 'phone', label: 'Phone', alignRight: false },
  { id: 'vehicle', label: 'Vehicle', alignRight: false },
  { id: 'date', label: 'Start Date', alignRight: false },
  { id: 'time', label: 'Duration', alignRight: false },
  { id: 'status', label: 'Status', alignRight: false },
  { id: 'image', label: 'Image', alignRight: false }
];

const RidesTable = () => {
  const [page, setPage] = useState(0);
  const [order] = useState('asc');
  const [selected] = useState([]);
  const [orderBy] = useState('name');
  const [filterName] = useState('');
  const [pageNumber] = useState(0);
  const [totalPages, setTotalPages] = useState(null);
  const [totalRides, setTotalRides] = useState(null);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [isRidesLoading, setIsRidesLoading] = useState(true);
  const [rides, setRides] = useState([]);
  const [fieldName] = useState('date'); // Default Sorting
  const [sortType] = useState('desc');
  const [searchedTerm, setSearchedTerm] = useState('');
  const [appliedFilters] = useState('');
  const [isError, setIsError] = useState(false);
  const [viewedImageUrl, setViewedImageUrl] = useState(null);
  const [errorMessage, setErrorMessage] = useState('Unexpected Error. Please check your network');

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const fetchRides = async () => {
    setIsRidesLoading(true);
    let variables = {
      pageNumber: pageNumber + 1,
      fieldName,
      sortType,
      rowsPerPage
    };

    if (appliedFilters !== '') variables = { ...variables, filters: appliedFilters };

    if (searchedTerm !== '')
      variables = { ...variables, searchTerm: searchedTerm.replace('+', '') };

    const header = {
      Authorization: `Bearer ${getToken('access-token')}`
    };

    try {
      const ridesResponse = await client.request(getRidesWithPage, variables, header);
      const ridesObject = ridesResponse.rides;
      setTotalPages(ridesObject.totalPages);
      setTotalRides(ridesObject.totalRides);
      setRides([...ridesObject.rides]);
      setIsRidesLoading(false);
    } catch (error) {
      if (error.response && error.response.status === 500) {
        setErrorMessage(error.response.errors[0].message);
      }
      setIsRidesLoading(false);
      setIsError(true);
      console.log(error);
    }
  };
  const handleSearchTermChange = (searchTerm) => {
    setSearchedTerm(searchTerm);
  };
  const handleAlertClose = () => {
    setIsError(false);
  };
  const handleModalClose = () => {
    setViewedImageUrl(null);
  };

  useEffect(() => {
    fetchRides();
  }, [searchedTerm, pageNumber, rowsPerPage]);

  const isUserNotFound = rides && rides.length === 0;
  return (
    <Container>
      <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
        <Typography variant="h4" gutterBottom>
          Rides Table
        </Typography>
      </Stack>
      <Card>
        <RideListToolbar
          numSelected={selected.length}
          filterName={filterName}
          handleSearchTermChange={handleSearchTermChange}
        />
        {isRidesLoading ? (
          <Skeleton variant="rectangular" width="100%">
            <div style={{ paddingTop: '50%' }} />
          </Skeleton>
        ) : (
          <>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                <RideListHead
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={USERLIST.length}
                  numSelected={selected.length}
                />
                <TableBody>
                  {rides.map((row) => {
                    const { _id, user, status, date, time, scooter, imageUrl } = row;
                    const totalRideMunites = time
                      ? time.toFixed(2)
                      : differenceInMinutes(new Date(), new Date(date)).toFixed(2);

                    return (
                      <TableRow hover key={_id} tabIndex={-1}>
                        <TableCell component="th" scope="row">
                          {user?.name}
                        </TableCell>
                        <TableCell align="left">{user?.phoneNumber}</TableCell>
                        <TableCell align="left">{scooter?.code}</TableCell>
                        <TableCell align="left">
                          {format(new Date(date), 'dd-MM-yyyy hh:mm a')}
                        </TableCell>
                        <TableCell align="left">{totalRideMunites} Mins</TableCell>
                        <TableCell align="left">
                          <Label
                            variant="ghost"
                            color={(status === 'done' && 'success') || 'warning'}
                          >
                            {sentenceCase(status)}
                          </Label>
                        </TableCell>
                        <TableCell align="left">
                          {imageUrl ? (
                            <Button onClick={() => setViewedImageUrl(imageUrl)}>View Image</Button>
                          ) : null}
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
                {isUserNotFound && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                        <SearchNotFound searchQuery={filterName} />
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            </TableContainer>

            <TablePagination
              rowsPerPageOptions={[10, 25, 50, 100]}
              component="div"
              count={totalRides}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </>
        )}
      </Card>
      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        open={isError}
        autoHideDuration={3000}
        onClose={handleAlertClose}
      >
        <Alert onClose={handleAlertClose} severity="error" variant="filled">
          {errorMessage}
        </Alert>
      </Snackbar>
      {viewedImageUrl && <ImageModal imageUrl={viewedImageUrl} handleClose={handleModalClose} />}
    </Container>
  );
};

export default RidesTable;
