// material
import { alpha, styled } from '@mui/material/styles';
import { Avatar, Card, Skeleton, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { emissionsSaved } from 'src/utils/graphql/queries/rides/ridesQueries';
import { getToken } from 'src/utils/tokenUtils';
import { client } from 'src/utils/graphql/GraphqlClient';

// ----------------------------------------------------------------------

const RootStyle = styled(Card)(({ theme }) => ({
  boxShadow: 'none',
  textAlign: 'center',
  padding: theme.spacing(5, 0),
  color: theme.palette.primary.darker,
  backgroundColor: theme.palette.primary.lighter
}));

const IconWrapperStyle = styled('div')(({ theme }) => ({
  margin: 'auto',
  display: 'flex',
  borderRadius: '50%',
  alignItems: 'center',
  width: theme.spacing(8),
  height: theme.spacing(8),
  justifyContent: 'center',
  marginBottom: theme.spacing(3),
  color: theme.palette.primary.dark,
  backgroundImage: `linear-gradient(135deg, ${alpha(theme.palette.primary.dark, 0)} 0%, ${alpha(
    theme.palette.primary.dark,
    0.24
  )} 100%)`
}));

// ----------------------------------------------------------------------

export default function EmissionsSavedCard() {
  const [lowAverage, setLowAverage] = useState();
  const [highAverage, setHighAverage] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('Unexpected Error. Please check your network');

  const getSavedEmissions = async () => {
    const variables = {};
    const header = {
      Authorization: `Bearer ${getToken('access-token')}`
    };

    try {
      const emissionsResponse = await client.request(emissionsSaved, variables, header);
      const emissionsObject = emissionsResponse.emissionsSaved;
      setHighAverage(emissionsObject.highAverage);
      setLowAverage(emissionsObject.lowAverage);
      setIsLoading(false);
    } catch (error) {
      if (error.response && error.response.status === 500) {
        setErrorMessage(error.response.errors[0].message);
      }
      setIsLoading(false);
      setIsError(true);
      console.log(error);
    }
  };
  useEffect(() => {
    getSavedEmissions();
  }, []);
  return (
    <>
      {!isLoading && highAverage && highAverage ? (
        <RootStyle>
          <IconWrapperStyle>
            <Avatar alt="Remy Sharp" src="/static/icons/co2.png" />
          </IconWrapperStyle>
          <Typography variant="h3">{`${lowAverage}-${highAverage} KG`}</Typography>
          <Typography variant="subtitle2" sx={{ opacity: 0.72 }}>
            Emissions Saved
          </Typography>
        </RootStyle>
      ) : (
        <Skeleton variant="rectangular" width="100%" height="100%" style={{ borderRadius: '16px' }}>
          <div style={{ paddingTop: '57%' }} />
        </Skeleton>
      )}
    </>
  );
}
