import React, { useState, useEffect } from 'react';
import {
  Card,
  Table,
  Stack,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
  Skeleton,
  Snackbar,
  Alert,
  CircularProgress,
  Backdrop
} from '@mui/material';
import { getToken } from 'src/utils/tokenUtils';
import { client } from 'src/utils/graphql/GraphqlClient';
import SearchNotFound from 'src/components/SearchNotFound';
import { getVehicles } from 'src/utils/graphql/queries/vehicles/VehicleQueries';
import { unlockVehicle } from 'src/utils/graphql/mutations/vehicles/VehiclesMutations';
import { VehicleListHead, VehicleListToolbar } from '.';
import CollapsableRow from './CollapsableRow';

const TABLE_HEAD = [
  { id: 'name', label: 'Name', alignRight: false },
  { id: 'realBatteryValue', label: 'Battery', alignRight: false },
  { id: 'lastModification', label: 'Last Update', alignRight: false },
  { id: 'lockStatus', label: 'Lock Status', alignRight: false },
  { id: 'status', label: 'Application Status', alignRight: false },
  { id: 'iotStatus', label: 'Physical Status', alignRight: false },
  { id: '' }
];

const VehiclesTable = () => {
  const [page] = useState(0);
  const [order] = useState('asc');
  const [selected] = useState([]);
  const [orderBy] = useState('name');
  const [filterName] = useState('');
  const [pageNumber, setPageNumber] = useState(0);
  const [totalPages, setTotalPages] = useState(null);
  const [totalVehicles, setTotalVehicles] = useState(null);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [isVehiclesLoading, setIsVehiclesLoading] = useState(true);
  const [isUnlockingVehicle, setIsUnlokcingVehicle] = useState(false);
  const [vehicles, setVehicles] = useState([]);
  const [sortType, setSortType] = useState('desc');
  const [searchedTerm, setSearchedTerm] = useState('');
  const [appliedFilters, setAppliedFilters] = useState('');
  const [isUnlockRequestSuccessful, setIsUnlockRequestSuccessful] = useState(false);
  const [isError, setIsError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('Unexpected Error. Please check your network');

  const handleChangePage = (event, newPage) => {
    setPageNumber(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPageNumber(0);
  };

  const fetchVehicles = async () => {
    setIsVehiclesLoading(true);
    let variables = {
      pageNumber: pageNumber + 1,
      rowsPerPage
    };

    if (appliedFilters !== '') variables = { ...variables, filters: appliedFilters };

    if (searchedTerm !== '')
      variables = { ...variables, searchTerm: searchedTerm.replace('+', '') };

    const header = {
      Authorization: `Bearer ${getToken('access-token')}`
    };

    try {
      const vehiclesResponse = await client.request(getVehicles, variables, header);
      const vehiclesObject = vehiclesResponse.vehicles;
      setTotalPages(vehiclesObject.totalPages);
      setTotalVehicles(vehiclesObject.totalNumberOfRows);
      setVehicles([...vehiclesObject.vehicles]);
      setIsVehiclesLoading(false);
    } catch (error) {
      if (error.response && error.response.status === 500) {
        setErrorMessage(error.response.errors[0].message);
      }
      setIsVehiclesLoading(false);
      setIsError(true);
      console.log(error);
    }
  };
  const handleUnlockClick = async (vehicleCode) => {
    setIsUnlokcingVehicle(true);
    const variables = {
      code: vehicleCode
    };

    const header = {
      Authorization: `Bearer ${getToken('access-token')}`
    };

    try {
      const unlockVehiclesResponse = await client.request(unlockVehicle, variables, header);
      const vehiclesObject = unlockVehiclesResponse.unlockVehicle;
      setIsUnlokcingVehicle(false);
      setIsUnlockRequestSuccessful(true);
    } catch (error) {
      if (error.response && error.response.status === 500) {
        setErrorMessage(error.response.errors[0].message);
      }
      setIsError(true);
      setIsUnlokcingVehicle(false);
      console.log(error);
    }
  };
  const handleSearchTermChange = (searchTerm) => {
    setSearchedTerm(searchTerm);
  };
  const handleAlertClose = () => {
    setIsError(false);
  };
  const handleUnlockAlertClose = () => {
    setIsUnlockRequestSuccessful(false);
  };

  useEffect(() => {
    fetchVehicles();
  }, [searchedTerm, pageNumber, rowsPerPage]);

  const isUserNotFound = vehicles && vehicles.length === 0;
  return (
    <Container>
      <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
        <Typography variant="h4" gutterBottom>
          Vehicles Table
        </Typography>
      </Stack>
      <Card>
        <VehicleListToolbar
          numSelected={selected.length}
          filterName={filterName}
          handleSearchTermChange={handleSearchTermChange}
        />

        {isVehiclesLoading ? (
          <Skeleton variant="rectangular" width="100%">
            <div style={{ paddingTop: '50%' }} />
          </Skeleton>
        ) : (
          <>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                <VehicleListHead
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={totalVehicles}
                  numSelected={selected.length}
                />
                <TableBody>
                  {vehicles.map((row) => (
                    <CollapsableRow rowData={row} handleUnlockClick={handleUnlockClick} />
                  ))}
                </TableBody>
                {isUserNotFound && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                        <SearchNotFound searchQuery={filterName} />
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            </TableContainer>

            <TablePagination
              rowsPerPageOptions={[10, 25, 50, 100]}
              component="div"
              count={totalVehicles}
              rowsPerPage={rowsPerPage}
              page={pageNumber}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </>
        )}
      </Card>
      {isUnlockingVehicle ? (
        <Backdrop sx={{ color: 'white', zIndex: (theme) => theme.zIndex.drawer + 1 }} open>
          <CircularProgress color="primary" />
        </Backdrop>
      ) : null}

      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        open={isError}
        autoHideDuration={3000}
        onClose={handleAlertClose}
      >
        <Alert onClose={handleAlertClose} severity="error" variant="filled">
          {errorMessage}
        </Alert>
      </Snackbar>
      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        open={isUnlockRequestSuccessful}
        autoHideDuration={3000}
        onClose={handleUnlockAlertClose}
      >
        <Alert onClose={handleAlertClose} severity="success" variant="filled">
          Successfully requested vehicle unlock!
        </Alert>
      </Snackbar>
    </Container>
  );
};

export default VehiclesTable;
