import { gql } from 'graphql-request';

export const getRidesWithPage = gql`
  query getRides(
    $pageNumber: Int!
    $rowsPerPage: Int!
    $fieldName: String
    $sortType: String
    $filters: String
    $searchTerm: String
  ) {
    rides(
      pageNumber: $pageNumber
      rowsPerPage: $rowsPerPage
      fieldName: $fieldName
      sortType: $sortType
      filters: $filters
      searchTerm: $searchTerm
    ) {
      totalPages
      totalRides
      rides {
        _id
        time
        date
        status
        endedBy
        imageUrl
        scooter {
          code
          iotStatus
          status
          vehicleType
          realBatteryValue
          batteryTravelRange
        }
        user {
          _id
          name
          phoneNumber
        }
      }
    }
  }
`;

export const emissionsSaved = gql`
  query emissionsSaved {
    emissionsSaved {
      highAverage
      lowAverage
    }
  }
`;

export const todayRidesCount = gql`
  query sumOfTodayRides {
    sumOfTodayRides
  }
`;
