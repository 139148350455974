import React, { useState, useEffect } from 'react';
import {
  Card,
  Table,
  Stack,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
  Skeleton,
  Snackbar,
  Alert
} from '@mui/material';
import Scrollbar from 'src/components/Scrollbar';
import { getToken } from 'src/utils/tokenUtils';
import { client } from 'src/utils/graphql/GraphqlClient';
import SearchNotFound from 'src/components/SearchNotFound';
import { getUsers } from 'src/utils/graphql/queries/users/UsersQueries';
import { UserListHead, UserListToolbar } from '.';

const TABLE_HEAD = [
  { id: 'name', label: 'Name', alignRight: false },
  { id: 'phoneNumber', label: 'Phone', alignRight: false },
  { id: 'email', label: 'Email', alignRight: false },
  { id: 'numberOfRides', label: 'Rides Number', alignRight: false }
];

const UsersTable = () => {
  const [order] = useState('asc');
  const [selected] = useState([]);
  const [orderBy] = useState('name');
  const [filterName] = useState('');
  const [pageNumber, setPageNumber] = useState(0);
  const [totalPages, setTotalPages] = useState(null);
  const [totalUsers, setTotalUsers] = useState(null);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [isUsersLoading, setIsUsersLoading] = useState(true);
  const [users, setUsers] = useState([]);
  const [sortType] = useState('desc');
  const [searchedTerm, setSearchedTerm] = useState('');
  const [appliedFilters] = useState('');
  const [isError, setIsError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('Unexpected Error. Please check your network');

  const handleChangePage = (event, newPage) => {
    setPageNumber(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPageNumber(0);
  };

  const fetchUsers = async () => {
    setIsUsersLoading(true);
    let variables = {
      pageNumber: pageNumber + 1,
      rowsPerPage
    };

    if (appliedFilters !== '') variables = { ...variables, filters: appliedFilters };

    if (searchedTerm !== '')
      variables = { ...variables, searchTerm: searchedTerm.replace('+', '') };

    const header = {
      Authorization: `Bearer ${getToken('access-token')}`
    };

    try {
      const usersResponse = await client.request(getUsers, variables, header);
      const usersObject = usersResponse.getExpressUsers;
      setTotalPages(usersObject.totalPages);
      setTotalUsers(usersObject.totalRows);
      setUsers([...usersObject.users]);
      setIsUsersLoading(false);
    } catch (error) {
      if (error.response && error.response.status === 500) {
        setErrorMessage(error.response.errors[0].message);
      }
      setIsUsersLoading(false);
      setIsError(true);
      console.log(error);
    }
  };
  const handleSearchTermChange = (searchTerm) => {
    setSearchedTerm(searchTerm);
  };
  const handleAlertClose = () => {
    setIsError(false);
  };

  useEffect(() => {
    fetchUsers();
  }, [searchedTerm, pageNumber, rowsPerPage]);

  const isUserNotFound = users && users.length === 0;
  return (
    <Container>
      <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
        <Typography variant="h4" gutterBottom>
          Team Users Table
        </Typography>
      </Stack>
      <Card>
        <UserListToolbar
          numSelected={selected.length}
          filterName={filterName}
          handleSearchTermChange={handleSearchTermChange}
        />
        {isUsersLoading ? (
          <Skeleton variant="rectangular" width="100%">
            <div style={{ paddingTop: '50%' }} />
          </Skeleton>
        ) : (
          <>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                <UserListHead
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={totalUsers}
                  numSelected={selected.length}
                />
                <TableBody>
                  {users.map((row) => {
                    const { _id, name, phoneNumber, email, numberOfRides } = row;
                    return (
                      <TableRow hover key={_id} tabIndex={-1}>
                        <TableCell component="th" scope="row">
                          {name}
                        </TableCell>
                        <TableCell align="left">{phoneNumber}</TableCell>
                        <TableCell align="left">{email}</TableCell>
                        <TableCell align="left">{numberOfRides}</TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
                {isUserNotFound && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                        <SearchNotFound searchQuery={filterName} />
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            </TableContainer>

            <TablePagination
              rowsPerPageOptions={[10, 25, 50, 100]}
              component="div"
              count={totalUsers}
              rowsPerPage={rowsPerPage}
              page={pageNumber}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </>
        )}
      </Card>
      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        open={isError}
        autoHideDuration={3000}
        onClose={handleAlertClose}
      >
        <Alert onClose={handleAlertClose} severity="error" variant="filled">
          {errorMessage}
        </Alert>
      </Snackbar>
    </Container>
  );
};

export default UsersTable;
