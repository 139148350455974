import React, { useState } from 'react';
import { Marker, InfoWindow } from '@react-google-maps/api';
import { useNavigate } from 'react-router-dom';
import { Button, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  detailsButton: {
    color: 'white'
  }
}));

const CustomMarker = ({ vehicle, icon, index }) => {
  const [open, setOpen] = useState(false);
  const classes = useStyles();
  const navigate = useNavigate();
  return (
    <>
      <Marker
        key={vehicle._id}
        onClick={(e) => {
          if (e) {
            e.domEvent.stopPropagation();
          }
          setOpen(true);
        }}
        position={{ lat: vehicle.lat, lng: vehicle.long }}
        icon={{
          url: icon
        }}
      >
        {open && (
          <InfoWindow
            onCloseClick={(e) => {
              if (e) {
                e.domEvent.stopPropagation();
              }
              setOpen(false);
            }}
          >
            <>
              <Typography variant="h4" component="h4" gutterBottom>
                {vehicle.code}
              </Typography>
              <Typography variant="body1" component="div" gutterBottom>
                {`Status : ${vehicle.status}`}
              </Typography>
              <Typography variant="body1" component="div" gutterBottom>
                {`Battery :  ${
                  vehicle.realBatteryValue?.toFixed(2) || (vehicle.battery / 0.2 + 12).toFixed(2)
                }%`}
              </Typography>
              {/* <Button
                color="primary"
                variant="contained"
                fullWidth
                className={classes.detailsButton}
                onClick={() => navigate(`/app/vehicle/${vehicle.code}`)}
              >
                Details
              </Button> */}
            </>
          </InfoWindow>
        )}
      </Marker>
    </>
  );
};
export default CustomMarker;
