import { merge } from 'lodash';
// material
import { Card, CardHeader, Box } from '@mui/material';
import Skeleton from '@mui/material/Skeleton';

//
import MapWithMarkers from 'src/components/maps/MapWithMarkers';
import { useEffect, useState } from 'react';
import { getToken } from 'src/utils/tokenUtils';
import { vehiclesWithLocationAndBattery } from 'src/utils/graphql/queries/vehicles/VehicleQueries';
import { client } from 'src/utils/graphql/GraphqlClient';

export default function VehiclesCoordinates() {
  const [isVehiclesLoading, setIsVehiclesLoading] = useState(true);
  const [vehiclesCoordinates, setVehicleCoordinates] = useState([]);
  const [errorMessage, setErrorMessage] = useState('Unexpected Error. Please check your network');
  const [isError, setIsError] = useState(false);
  useEffect(() => {
    const fetchVehicles = async () => {
      const header = {
        authorization: `Bearer ${getToken('access-token')}`
      };
      const varaibles = {};

      try {
        const vehiclesCoordiantesResponse = await client.request(
          vehiclesWithLocationAndBattery,
          varaibles,
          header
        );
        const vehiclesArray = vehiclesCoordiantesResponse.vehiclesWithLocationAndBattery;
        setVehicleCoordinates(vehiclesArray);
        setIsVehiclesLoading(false);
      } catch (error) {
        if (error.response && error.response.status === 500) {
          setErrorMessage(error.response.errors[0].message);
        }
        setIsError(true);
        console.log(error);
      }
    };
    fetchVehicles();
  }, []);
  return (
    <Card>
      {isVehiclesLoading ? (
        <Skeleton variant="rectangular" width="100%">
          <div style={{ paddingTop: '50%' }} />
        </Skeleton>
      ) : (
        <>
          <CardHeader title="Vehicles Coordinates" />
          <Box sx={{ p: 3, pb: 1 }} dir="ltr">
            <MapWithMarkers vehiclesWithCoordinates={vehiclesCoordinates} />
          </Box>
        </>
      )}
    </Card>
  );
}
