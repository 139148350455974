import React, { useRef } from 'react';
import { GoogleMap, Marker, useJsApiLoader } from '@react-google-maps/api';

const containerStyle = {
  width: '100%',
  height: '400px'
};

function VehicleLocationMap({ coordinates }) {
  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: 'AIzaSyC6vfMlg8weB3DKX2-ZF5esMSXbCE1tHYA'
  });

  const [map, setMap] = React.useState(null);
  const mapRef = useRef(null);

  const onLoad = React.useCallback((map) => {
    setMap(map);
    mapRef.current = map;
  }, []);

  const onUnmount = React.useCallback((map) => {
    setMap(null);
  }, []);

  return isLoaded ? (
    <GoogleMap
      mapContainerStyle={containerStyle}
      center={coordinates}
      zoom={18}
      onLoad={onLoad}
      onUnmount={onUnmount}
    >
      <Marker onLoad={onLoad} position={coordinates} />
    </GoogleMap>
  ) : (
    <></>
  );
}

export default React.memo(VehicleLocationMap);
