import { gql } from 'graphql-request';

export const getVehicles = gql`
  query vehicles($pageNumber: Int!, $rowsPerPage: Int!, $filters: String, $searchTerm: String) {
    vehicles(
      pageNumber: $pageNumber
      rowsPerPage: $rowsPerPage
      searchTerm: $searchTerm
      filters: $filters
    ) {
      vehicles {
        _id
        status
        iotStatus
        lat
        long
        phoneBattery
        lockStatus
        lastModification
        battery
        realBatteryValue
        batteryTravelRange
        code
      }
      totalPages
      totalNumberOfRows
    }
  }
`;

export const getVehicle = gql`
  query getVehicle($code: String!) {
    vehicle(code: $code) {
      _id
      code
      long
      lat
      isInStorage
      area {
        name
      }
      lastModification
      status
      rating
      phoneBattery
      battery
      realBatteryValue
      batteryTravelRange
      locationAccuracy
      iotStatus
      isRestartButtonVisible
      filteredLat
      filteredLong
      locationsDifference
      lockStatus
      isScooterWithLock
      vehicleType
    }
  }
`;

export const getUrgentScooters = gql`
  query getUrgentScooters {
    getUrgentScootersDetails {
      _id
      code
      long
      lat
      isInStorage
      area {
        name
      }
      lastModification
      status
      rating
      phoneBattery
      battery
      realBatteryValue
      batteryTravelRange
      iotStatus
    }
  }
`;

export const getUrgentVehiclesCount = gql`
  query getUrgentVehiclesCount {
    getUrgentVehiclesCount
  }
`;

export const getOutOfFenceVehicles = gql`
  query getOutOfFenceVehicles($pageNumber: Int!, $rowsPerPage: Int!) {
    getOutOfFenceVehiclesWithRide(rowsPerPage: $rowsPerPage, pageNumber: $pageNumber) {
      outOfFenceVehicles {
        vehicle {
          _id
          status
          iotStatus
          lastModification
          phoneBattery
          battery
          realBatteryValue
          batteryTravelRange
          code
          lat
          long
          area {
            name
          }
        }
        ride {
          status
          user {
            name
            phoneNumber
          }
        }
      }
      totalOutOfFenceVehiclesNumber
    }
  }
`;
export const getAutoEndUnlockedVehicles = gql`
  query getUrgentVehiclesViaFlag {
    getUrgentVehiclesViaFlag {
      _id
      code
      long
      lat
      isInStorage
      area {
        name
      }
      lastModification
      status
      rating
      phoneBattery
      battery
      realBatteryValue
      batteryTravelRange
      iotStatus
      lockStatus
      isUrgent
    }
  }
`;
export const getAllUrgentVehicles = gql`
  query getAllUrgentVehicles {
    getAllUrgentVehicles {
      _id
      code
      long
      lat
      isInStorage
      vehicleType
      area {
        name
      }
      lastModification
      status
      rating
      phoneBattery
      battery
      realBatteryValue
      batteryTravelRange
      iotStatus
      isUrgent
    }
  }
`;

export const vehiclesWithLocationAndBattery = gql`
  query vehiclesWithLocationAndBattery {
    vehiclesWithLocationAndBattery {
      _id
      code
      status
      iotStatus
      long
      lat
      vehicleType
      realBatteryValue
      battery
    }
  }
`;
