import React, { useCallback, useRef } from 'react';
import { GoogleMap, useLoadScript } from '@react-google-maps/api';
import CustomMarker from './CustomMarker';

const containerStyle = {
  width: '100%',
  height: '400px'
};

const MapWithMarkers = ({ vehiclesWithCoordinates }) => {
  const { isLoaded } = useLoadScript({
    id: 'google-map-script',
    googleMapsApiKey: 'AIzaSyC6vfMlg8weB3DKX2-ZF5esMSXbCE1tHYA'
  });

  const [map, setMap] = React.useState(null);
  const mapRef = useRef(null);

  const onLoad = useCallback((map) => {
    setMap(map);
    mapRef.current = map;
  }, []);
  const center = {
    lat: 30.066,
    lng: 31.4856
  };

  return isLoaded ? (
    <GoogleMap mapContainerStyle={containerStyle} onLoad={onLoad} center={center} zoom={11}>
      {vehiclesWithCoordinates &&
        vehiclesWithCoordinates.map((vehicle, index) => {
          let icon = '/static/icons/bike-available.png';
          if (vehicle.status === 'off') {
            icon = '/static/icons/bike-offline.png';
          } else if (vehicle.iotStatus === 'on') {
            icon = '/static/icons/bike-inride.png';
          }
          return <CustomMarker key={index} vehicle={vehicle} icon={icon} />;
        })}
    </GoogleMap>
  ) : (
    <></>
  );
};

export default React.memo(MapWithMarkers);
